/*--------------------------------------------------------------
# Fonts
--------------------------------------------------------------*/
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.uploading-file {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  gap: 5px;
  padding-right: 1rem;
}
.MuiAvatarGroup-avatar {
  width: 30px;
  height: 30px;
}
.image-hov:hover {
  opacity: 0.7;
}

.card-hov:hover {
  opacity: 0.7;
}

.list-hov:hover {
  background-color: rgba(0, 171, 85, 0.08);
}
.task-date {
  color: rgb(0, 171, 85);
  text-decoration: underline;
  font-size: 12px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: max-content;
}
.react-datepicker__time-container {
  width: 90px !important;
}
.react-datepicker__time-box {
  width: 90px !important;
}
/* .MuiTableCell-body{
    padding-top: 0px;
    padding-bottom: 0px;
} */
/* task time picker */
.time-display {
  text-decoration: underline;
  cursor: pointer;
}
.onDrag {
  background-color: rgba(0, 171, 85, 0.08);
}

.not-drag {
  background-color: white;
}

.search-input:focus {
  outline: 1px solid rgba(0, 171, 85, 0.08);
}
/* .search-input {
    background-color:white;
} */

/* .css-1tax2ty-MuiTablePagination-selectLabel{
    margin-top: 13px;
}
MuiTablePagination-toolbar
.css-1agq8o-MuiTablePagination-displayedRows{
    margin-top: 13px;
} */
.MuiTablePagination-toolbar {
  align-items: baseline;
}

.input-field {
  padding-right: 15px;
  padding-top: 15px;
  padding-left: 15px;
  padding-bottom: 5px;
  /* position: absolute;
    background-color: white; */
  position: fixed;
  background-color: white;
  z-index: 1;
}

.status-circle {
  cursor: pointer;
  min-height: 25px;
  min-width: 25px;
  color: #fff;
  border-radius: 50%;
  font-size: 12px;
  text-align: center;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.count-circle {
  cursor: pointer;
  min-height: 18px;
  min-width: 18px;
  color: #fff;
  border-radius: 50%;
  font-size: 12px;
  text-align: center;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(0, 171, 85);
  /* background-color: rgb(51, 102, 255); */
}
.ans-day {
  /* background: rgb(246, 247, 248); */
  color: rgb(0, 171, 85);
  margin-left: 4px;
  margin-right: 4px;
  border-radius: 2px;
  padding: 4px;
}
.date-css {
  align-self: center;
}

.main-header {
  border: 1px solid #c5c5c5;
  border-radius: 10px;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 0px !important;
}

.header-detail {
  align-items: center;
}

.header-task {
  display: flex;
  justify-content: space-evenly;
  /* border-right: 1px solid #c5c5c5;
    height: 100%; */
}

.header-date {
  display: flex;
  justify-content: flex-end;
  /* border-right: 1px solid #c5c5c5;
    height: 100%; */
}

.header-progress {
  /* border-right: 1px solid #c5c5c5;
    height: 100%;
    padding-top: 13.5px; */
  display: flex;
  justify-content: space-around;
}

.header-members {
}

.text-area-task {
  resize: none;
}

.simplebar-wrapper {
  height: 80vh;
  overflow: hidden;
}
.css-qw2e6q {
  height: 400px;
}
.simplebar-content-wrapper {
  overflow: scroll;
}

.task-label {
  font-family: "Montserrat", sans-serif;
}

.comments-outerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-bottom: 5px; */
}

.comments-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #ffffff;
  border-radius: 8px;
  height: 93vh;
  width: 93%;
}

.comments-field {
  background-color: rgb(0 42 21 / 8%);
  font-size: 12px;
  resize: none;
}
.comments-input {
  display: flex;
  justify-content: space-between;
}

.messages {
  flex: auto;
  padding-inline: 5px;
  overflow: auto;
}

.messageBox {
  background: #f3f3f3;
  border-radius: 20px;
  padding: 5px 20px;
  color: white;
  display: inline-block;
  max-width: 80%;
}

.messageBox {
  background: #f3f3f3;
  border-radius: 20px;
  padding: 5px 10px;
  color: white;
  display: inline-block;
  max-width: 80%;
}

.messageText {
  width: 100%;
  letter-spacing: 0;
  float: left;
  font-size: 1.1em;
  word-wrap: break-word;
}

.messageText img {
  vertical-align: middle;
}

.messageContainer {
  display: flex;
  justify-content: flex-end;
  padding: 0 5%;
  margin-top: 3px;
}

.sentText {
  display: flex;
  align-items: center;
  font-family: Helvetica;
  color: #828282;
  letter-spacing: 0.3px;
}

.comments-user-image {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.pl-10 {
  padding-left: 10px;
}

.pr-10 {
  padding-right: 10px;
}

.justifyStart {
  justify-content: flex-start;
}

.justifyEnd {
  justify-content: flex-end;
}

.colorWhite {
  color: white;
}

.colorDark {
  color: #353535;
}

.backgroundBlue {
  background: #00ab55;
}

.backgroundLight {
  background: #f3f3f3;
}

.infoBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #00ab55;
  border-radius: 4px 4px 0 0;
  height: 20px;
  width: 100%;
}

.leftInnerContainer {
  flex: 0.5;
  display: flex;
  align-items: center;
  margin-left: 2%;
  color: white;
}

.rightInnerContainer {
  display: flex;
  flex: 0.5;
  justify-content: flex-end;
  margin-right: 2%;
}

.onlineIcon {
  margin-right: 5%;
}
/* comments UI */
.comment-card {
  margin-block: 10px;
  border: solid 1px rgb(197, 197, 197);
  border-radius: 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
.section-title-div {
  padding-top: 10px;
}
.section-card-div {
  background-color: #3ab469;
  /* background-color: #ebf8f2; */
  margin-top: 20px;
  /* color: black !important; */
}
/* CKEditor styles */
.task-description-editor-parent
  .ck.ck-editor__main
  > .ck-editor__editable:not(.ck-focused) {
  min-height: 28vh !important;
}
.task-description-editor-parent
  .ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-focused {
  min-height: 28vh !important;
  border: solid 1px #3ab469;
}
.commentItemParent .ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
  min-height: 15vh !important;
}
.commentItemParent
  .ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-focused {
  min-height: 15vh !important;
  border: solid 1px #3ab469;
}
.project-description
  .ck.ck-editor__main
  > .ck-editor__editable:not(.ck-focused) {
  min-height: 15vh !important;
}
.project-description
  .ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-focused {
  min-height: 15vh !important;
  border: solid 1px #3ab469;
}
/*  */
.product-description
  .ck.ck-editor__main
  > .ck-editor__editable:not(.ck-focused) {
  min-height: 20vh !important;
}
.product-description
  .ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-focused {
  min-height: 20vh !important;
  border: solid 1px #3ab469;
}


.project-description1
  .ck.ck-editor__main
  > .ck-editor__editable:not(.ck-focused) {
  min-height: 60vh !important;
}
.project-description1
  .ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-focused {
  min-height: 60vh !important;
  border: solid 1px #3ab469;
}
/*  */
.product-description1
  .ck.ck-editor__main
  > .ck-editor__editable:not(.ck-focused) {
  min-height: 80vh !important;
}
.product-description1
  .ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-focused {
  min-height: 80vh !important;
  border: solid 1px #3ab469;
}

/*  */
.project-name-div {
  color: rgb(0, 171, 85);
  font-weight: 500;
  background-color: rgb(246, 247, 248);
  padding: 10px 10px;
  border-radius: 5px;
}
/*task Listing styles  */
.not-drag-col-outer {
  border-left: 3px solid transparent;
  border-radius: 4px;
  margin-block: 10px;
  padding-block: 5px;
  padding-bottom: 10px;
}
.theme-text-color {
  color: rgb(0, 171, 85);
}
.section-card-div {
  background-color: #3ab469;
  /* background-color: #ebf8f2; */
  margin-top: 20px;
  /* color: black !important; */
}
.section-text {
  font-size: 16;
  font-weight: bold;
  padding-top: 5px;
}
/* loader */
.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: -webkit-translate(-50%, -50%);
  transform: -moz-translate(-50%, -50%);
  transform: -ms-translate(-50%, -50%);
}
/* to avoid ol list going outside of container */
#task-description-admin > ol {
  margin-left: 17px;
}
/* tooltip html */
#task-description-tooltip {
  margin-left: 10px;
  margin-right: 10px;
}
#task-description-tooltip > ol {
  margin-left: 10px;
  margin-right: 10px;
}
/* new comment indicator */
.new-comment-indicator {
  position: absolute;
  left: -2px;
  bottom: 6px;
}
.sale-link {
  color: rgb(0, 171, 85);
  text-decoration: none;
  list-style: none;
  margin-left: 4px;
  margin-right: 4px;
  padding-top: 8px;
}
.sale-link:hover {
  color: rgb(0, 171, 85);
}
.messages a {
  line-break: anywhere !important;
}
.comments-container a {
  line-break: anywhere !important;
}
/* chat UI*/
/* padding coming from MainStyle component */
.common-border {
  border: solid 1px transparent;
  border-radius: 12px;
}
div[class^="react-scroll-to-bottom"],
div[class*="react-scroll-to-bottom"] {
  overflow-x: hidden !important;
}
.floating-image-bar {
  position: absolute;
  /* background-color: #f4f6f8; */
  height: 52px;
  padding-top: 4px;
  padding-bottom: 4px;
  width: 97.8%;
  bottom: 57px;
  z-index: 2;
}
.chat-users {
  height: 91.8vh;
  background-color: #f4f6f8;
  border-right: solid 1px rgba(128, 128, 128, 0.479);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  position: relative;
  overflow-y: hidden;
}
.info-message {
  background-color: #c9f3e094;
  border-radius: 8px;
  padding: 0.3rem;
}
.no-groups {
  position: absolute;
}
.user-card-parent {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  /* border: solid 1px black; */
  cursor: pointer;
  padding: 0.2rem;
}
.user-card-parent:hover {
  background-color: #3dda791c;
}
.chat-messages-parent {
  height: 84vh;
  background-color: #f4f6f8;
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}

.chat-messages {
  display: block;
}
.chat-messages-full {
  display: none;
}
.chat-list-button-container {
  display: none;
  position: relative;
  bottom: 0.5rem;
  left: -0.5rem;
}
.chat-users-hidden {
  display: none;
}
.load-more-chat {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 3;
  width: 100%;
}
.add-chat-btn-hidden {
  background-color: rgb(0, 171, 85);
  position: absolute;
  right: 11px;
  bottom: 19px;
}
.add-chat-btn-hidden:hover {
  background-color: rgb(0, 171, 85);
}
@media screen and (max-width: 768px) {
  .chat-messages-full {
    display: block;
  }
  .chat-messages {
    display: none;
  }
  .chat-users {
    display: none;
  }
  .chat-list-button-container {
    display: block;
  }
  .chat-users-hidden {
    display: block;
  }
}
.messages-container {
  height: 75.5vh;
  /* overflow-y: ; */
  z-index: 1;
  margin-bottom: 4px;
  /* height: 54vh; */
  /* background: #828282; */
}
.scroller {
  height: inherit;
  overflow-y: auto;
  overflow-x: hidden;
}
.chat-bottom-bar {
  position: relative;
}
.chat-container {
  /* position: relative; */
  /* top: 2rem; */
  background-color: #f4f6f8;
}

/* ck editor for chat message */
.message-editor {
  width: 100%;
  position: relative;
  /* bottom: -56px; */
  /* bottom: -7.2vh; */
}
.message-image-container {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 0.3rem;
  height: 50px;
  overflow: hidden;
  padding: 0.1rem;
  margin-bottom: 0.1rem;
  position: relative;
}
.message-image-cross {
  position: absolute;
  color: rgba(255, 0, 0, 0.808);
  top: -2px;
  left: 43px;
  cursor: pointer;
  z-index: 4;
}
.hidden-div-editor {
  position: absolute;
  min-height: 0.4rem;
  background-color: #f4f6f8;
  min-width: 100%;
  top: -6px;
  /* top: -0.4rem; */
  z-index: 2;
}
.message-editor .ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
  height: 13.5vh !important;
}
.message-editor
  .ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-focused {
  height: 13.5vh !important;
  border: solid 1px #3ab469;
}
.new-message-editor
  .ck.ck-editor__main
  > .ck-editor__editable:not(.ck-focused) {
  height: 18.5vh !important;
}
.new-message-editor
  .ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-focused {
  height: 18.5vh !important;
  border: solid 1px #3ab469;
}
.chat-tabs {
  margin-top: 1rem;
}
.chat-tab-btn {
  box-shadow: none;
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px; */
}
.add-chat-btn {
  background-color: rgb(0, 171, 85);
  position: absolute;
  right: 11px;
  bottom: 11px;
}
.add-chat-btn:hover {
  background-color: rgb(0, 171, 85);
}
.center-progress {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.clickable-link {
  font-size: 14px;
  font-weight: 400;
}
.group-menu-icon {
  position: relative;
  bottom: 6px;
}
.message-item {
  /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.212); */
  border: solid 1px rgba(145, 158, 171, 0.24);
  border-radius: 4px;
  padding: 0.3rem;
  margin: 0.1rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  position: relative;
  min-width: 10rem;
  max-width: 96%;
}
/* over-riding editor html tags styling */
.message-item p {
  margin: 0px !important;
}

.message-item ol {
  margin-left: 1rem;
}
.message-item ul {
  margin-left: 1.2rem;
}
.message-editor ul,
ol {
  margin-left: 1rem;
}
.message-item img {
  margin: auto;
}

.active-group {
  background-color: #c9f3e094;
  border: solid 1px transparent;
  border-radius: 4px;
}
.creator-message-time {
  position: absolute;
  right: 5px;
  bottom: 2px;
}
.creator-message-time * {
  font-size: 11px;
}
.sender-message-time {
  position: absolute;
  right: 5px;
  bottom: 2px;
}
.sender-message-time * {
  font-size: 11px;
}
.sent-message {
  background-color: rgba(255, 255, 255);
}
.received-message {
  background-color: rgba(145, 255, 200, 0.8);
  /* background-color: rgba(163, 208, 255, 0.8); */
}
.no-data {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.chat-avatar {
  height: 33px;
  width: 33px;
  border: solid 1px rgb(126, 126, 126);
}

.send-message-btn {
  position: absolute;
  bottom: 0.1rem;
  right: 1.1rem;
  /* bottom: 1px;
  right: 18px; */
}
.send-message-btn-left {
  position: absolute;
  bottom: 0.1rem;
  right: 2.1rem;
  /* bottom: 0px;
  right: 34px; */
}
.hidden-element {
  display: none;
  visibility: hidden;
}
.team-list-parent {
  padding: 0.5rem;
  border-radius: 4px;
  cursor: pointer;
  justify-content: start;
  align-items: center;
}
.messages-count {
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
}
.chat-indicator {
  position: absolute;
  bottom: 17px;
  right: 125px;
}
.message-margin {
  margin-top: 0.7rem;
  margin-bottom: 0.7rem;
}
.reaction-parent {
  position: absolute;
  cursor: pointer;
  right: -15px;
  top: -11px;
}
.reaction-inner {
  position: relative;
}
.emoji-bar {
  /* position: absolute; */
  /* background-color: #353535; */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.3rem;
}
.message-reactions-parent {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.3rem;
  left: -5px;
  bottom: -25px;
}
.floating-search {
  z-index: 9999;
  display: flex;
  position: absolute;
  left: 50%;
  top: 5px;
  transform: translateX(-50%) !important;
  justify-content: end;
  width: 50%;
}
.send-message-area {
  width: 100%;
  height: 3rem;
  border-radius: 0.5rem;
  resize: none;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.5rem;
}
.text-area-parent {
  position: relative;
}
.send-icon-parent {
  position: absolute;
  z-index: 10;
  top: 5px;
  right: 2px;
}
.send-message-area:active,
:focus,
:focus-visible,
:focus-within {
  outline: none;
}
/* scrollbar styles for text area */
.send-message-area::-webkit-scrollbar {
  max-height: 0.28rem !important;
  max-width: 0.28rem;
}
.send-message-area::-webkit-scrollbar-track {
  background: rgba(233, 233, 233, 0);
}
.send-message-area::-webkit-scrollbar-thumb {
  background-color: #a5a5a57e;
  border-radius: 20px;
}

/*  */
.pointer {
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  .new-msg-sidebar-mobile {
    position: relative;
    top: 0px;
  }
}
@media screen and (max-width: 768px) {
  .chat-messages-parent {
    height: 81vh;
  }
  .messages-container {
    height: 72.5vh;
  }
}

.scroller::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.scroller::-webkit-scrollbar {
  width: 0.4rem;
  background-color: #f5f5f5;
}

.scroller::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #bebebe;
  /* background-color: #8383839c; */
}

/* ====================================== for ipad screen ============================== */

/* @media only screen and (max-width: 768px) {
    .header-detail{
        align-items: center;
        height: 60px;
    }
    
    .header-task{
        display: flex;
        justify-content: space-evenly;
        border-right: 1px solid #c5c5c5;
        height: 100%;
    }
    
    .header-date{
        display: flex;
        justify-content: flex-end;
        border-right: 1px solid #c5c5c5;
        height: 100%;
    }
    
    .header-progress{
        border-right: 1px solid #c5c5c5;
        height: 100%;
        padding-top: 13.5px;
        padding-left: 3px;
    }
    
    .header-members{
        padding: 0px;
       
    }
} */
/* ====================================== for iphone screen ============================== */

/* @media only screen and (max-width: 375px) {
    .header-detail{
        align-items: center;
        height: 60px;
    }
    
    .header-task{
        display: flex;
        justify-content: space-evenly;
        border-right: 1px solid #c5c5c5;
        height: 100%;
    }
    
    .header-date{
        display: flex;
        justify-content: flex-end;
        border-right: 1px solid #c5c5c5;
        height: 100%;
    }
    
    .header-progress{
        border-right: 1px solid #c5c5c5;
        height: 100%;
        padding-top: 13.5px;
        padding-left: 3px;
    }
    
    .header-members{
        padding: 0px;
       
    }
} */

.pointer {
  cursor: pointer;
}
.theme-text-color {
  color: rgb(0, 171, 85);
}
.section-wrapper {
  border: 1px solid #dee2e6;
  padding-inline: 0.6rem;
  padding-block: 0.7rem;
  border-radius: 4px;
}
.task-section-name {
  font-weight: 700 !important;
}
.sale-section-name {
  font-weight: 700 !important;
}
.task-card-wrapper {
  min-height: 6rem;
  display: flex;
}
.def-task-section-label {
  background: rgb(0, 171, 85);
  border-radius: 3px;
  padding: 1px 5px;
  color: white;
  font-weight: bold;
  font-size: 12px;
  min-width: 1rem;
  padding-block: 3px;
  text-align: center;
}
.def-task-section-count {
  background: rgb(0, 171, 85);
  border-radius: 3px;
  padding: 1px 5px;
  color: white;
  font-weight: bold;
  font-size: 12px;
  min-width: 1rem;
  padding-block: 3px;
  text-align: center;
}
.def-sale-section-count {
  background: rgb(0, 171, 85);
  border-radius: 3px;
  padding: 1px 5px;
  color: white;
  font-weight: bold;
  font-size: 12px;
  min-width: 1rem;
}
.action-icon-color {
  color: #66727c !important;
}
